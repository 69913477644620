import { ButtonHTMLAttributes, useEffect, useState } from "react";

export const SubmitButton = ({
  loading,
  children,
  dark,
  className,
  ...props
}: {
  loading?: boolean;
  children: JSX.Element | string;
  dark?: boolean;
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (loading) {
      timeout = setTimeout(() => setShowLoading(true), 300);
    } else {
      setShowLoading(false);
    }
    return () => clearTimeout(timeout);
  }, [loading]);

  return (
    <button
      {...props}
      disabled={loading}
      className={className}
      style={{ display: "inline-flex", alignItems: "center", flexDirection: "column" }}
    >
      <div style={showLoading ? { height: 0, opacity: 0 } : null}>{children}</div>
      {showLoading && (
        <i className="bi bi-opencollective spin" style={{ opacity: 0.75 }} />
      )}
    </button>
  );
};
