type DealStatus = "accepted_offer" | "countered" | "connected" | "invited" | "matched";

export const dealStatuses = [
  "accepted_offer",
  "countered",
  "connected",
  "invited",
  "null",
];

export const dealStatusBadge = (status: DealStatus) => {
  return {
    accepted_offer: <span className="badge text-bg-warning">Accepted</span>,
    countered: <span className="badge text-bg-warning">Countered</span>,
    connected: <span className="badge text-bg-info">Account created</span>,
    invited: <span className="badge text-bg-light">Email Sent</span>,
    null: <span className="badge text-bg-light">Matched</span>,
  }[status || "null"];
};

export const dealStatusesForAdmin = [
  { value: "accepted_offer", label: "Accepted" },
  { value: "countered", label: "Countered" },
  { value: "connected", label: "Account created" },
  { value: "invited", label: "Email sent" },
  { value: "null", label: "Matched" },
];
