import { useState } from "react";
import { Accordion } from "react-bootstrap";

const Faqs = ({ faqs }: { faqs: { title: string; body: string }[] }) => (
  <div className="wrapper-faq mt-18 ">
    <h6 className="mb-4 fw-normal text-center">Frequently Asked Questions</h6>

    {faqs.map((faq, i) => (
      <FaqItem question={faq.title} answer={faq.body} key={faq.title} />
    ))}
  </div>
);

export default Faqs;

export const FaqItem = ({ question, answer }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="faq-accordion">
      <div
        className="faq-accordion-question flex space-between fs-14 bold pointer"
        onClick={() => setShow(!show)}
      >
        <div>{question}</div>
        <i
          className="bi bi-chevron-right faq-accordion-chevron"
          style={show ? { transform: "rotate(90deg)" } : null}
        />
      </div>
      {show && <div className="faq-accordion-answer">{answer}</div>}
    </div>
  );
};
